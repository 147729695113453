<script>
	import { page } from "$app/stores";

	$: ({ hostname } = $page);
</script>

<footer class="flex flex-shrink flex-col justify-end space-y-5 pt-20">
	<div
		class="flex flex-col-reverse md:flex-row md:items-center md:justify-between"
	>
		<a
			href="https://sites.google.com/txm-methods.com/centraldechamadostxm/início"
			rel="noopener noreferrer"
			target="_blank"
		>
			<button class="mt-10 flex justify-center space-x-4 md:mt-0">
				<div>
					<img alt="" src="/img/footer/icon_atention.svg" />
				</div>
				<p class="text-gray-brand-200">Relatar um problema</p>
			</button>
		</a>
		<div class="flex flex-row justify-between">
			<div>
				<a href="https://txm.business/">
					<img alt="" src="/img/footer/logo_txm_footer.svg" />
				</a>
			</div>
			{#if hostname && hostname.includes("nascer")}
				<a href="http://sebrae.sc/" target="_blank">
					<div class="md:hidden">
						<img alt="" src="/img/footer/logo_nascer.svg" />
					</div>
				</a>
			{:else}
				<a href="https://cocreationlab.com.br/" target="_blank">
					<div class="md:hidden">
						<img alt="" src="/img/footer/logo_cocreation_footer.svg" />
					</div>
				</a>
			{/if}
		</div>
		{#if hostname && hostname.includes("nascer")}
			<a href="http://sebrae.sc/" target="_blank">
				<div class="hidden md:flex">
					<img alt="" src="/img/footer/logo_nascer.svg" />
				</div>
			</a>
		{:else}
			<a href="https://cocreationlab.com.br/" target="_blank">
				<div class="hidden md:flex">
					<img alt="" src="/img/footer/logo_cocreation_footer.svg" />
				</div>
			</a>
		{/if}
	</div>
	<div>
		<p class="hidden text-center text-neutral-300 md:block">
			Copyright © TXM Business - 2019-2029
		</p>
	</div>
</footer>
<!-- <Modal bind:this={modal} outerClass="w-2/3 items-center ">
	<div class="flex flex-col p-10 w-full h-full modal gap-4">
		<div>
			<h1 class="text-3xl">Relate seu Problema</h1>
			<h2 class="text-xl">Assim que possível verificaremos a melhor forma de resolvê-lo.</h2>
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-lg"
			>Em qual página da plataforma você estava quando o problema ocorreu?</span
			>
			<input class="w-full input p-2 rounded-2xl" type="text" />
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-lg"
			>Qual foi o problema (o que você estava fazendo e o que não funcionou)?</span
			>
			<textarea class="w-full input p-2 rounded-2xl" type="text" />
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-lg">Possui algo a mais para relatar?</span>
			<textarea class="w-full input p-2 rounded-2xl" type="text" />
		</div>
		<div class="flex justify-end gap-4">
			<button class="button" on:click={modal.close}>Cancelar</button>
			<button class="button">Enviar</button>
		</div>
	</div>
</Modal> -->
